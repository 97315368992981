import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"


const About = ({ data }) => (
  <Layout>
    <div className="content">
      <header className="intro_text text-dark" style={{ maxWidth: "640px", paddingTop: "3rem", marginBottom: "5rem" }}>
        <div className="intro_container">
          <span className="text-small text-medium uppercase text-primary">
            {data.datoCmsTopSectionIntro.headline}
          </span>
          <h3 className="uppercase text-bold text-sans-serif text-display-xl">
            {data.datoCmsTopSectionIntro.line1}
            <span className="text-grey-two text-regular">{data.datoCmsTopSectionIntro.inline}</span>
            <br />
            {data.datoCmsTopSectionIntro.line2}
          </h3>
          <p className="text-display-m hero_content_spacing">
            {data.datoCmsTopSectionIntro.snippet}
          </p>
        </div>
      </header>

      {data.about.edges.map(({ node: fact }) => (
        <article key={fact.id} style={{ maxWidth: "700px" }}>
          <h3>{fact.headline}</h3>
          <div dangerouslySetInnerHTML={{
            __html: fact.textNode.childMarkdownRemark.html
          }} />
        </article>
      ))}
    </div>
  </Layout>
)

export default About

export const query = graphql`
  query AboutQuery {
    about: allDatoCmsAboutMe {
      edges {
        node {
          id
          headline
          textNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    datoCmsTopSectionIntro(sectionName: {eq: "about"}) {
      headline
      line1
      inline
      line2
      snippet
    }
  }
`
